/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	//background-color: ${({ theme }) => theme.colors.gradients.leftBlueToDarkBlue};
	height: 100vh;
	left: 0;
	position: relative;
	top: 0;
	transition: opacity 0.3s ease-in-out;
	width: 100%;

	&.light {
		background-color: ${({ theme }) => theme.colors.white};
	}
`;

function Overlay({ light = false }) {
	const OverlayRef = useRef(null);
	return <Wrapper className={`${light && 'light'}`} ref={OverlayRef} />;
}

export default Overlay;
