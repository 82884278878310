/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { useEffect, useState } from 'react';
import useMediaQuery from './useMediaQuery';
import themeSettings from '../styles/themeSettings';

export default function useIsDesktopVersion() {
	const [isDesktopVersion, setIsDesktopVersion] = useState(true);
	const isTabletLandscape = useMediaQuery(`(min-width:${themeSettings.breakpoints.tablet}px) and (orientation: landscape)`);
	const isDesktop = useMediaQuery(`(min-width:${themeSettings.breakpoints.desktop}px)`);
	useEffect(() => {
		if (isTabletLandscape || isDesktop) setIsDesktopVersion(true);
		else setIsDesktopVersion(false);
	}, [isTabletLandscape, isDesktop]);
	return isDesktopVersion;
}
