/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import CategoryTab from '../categoryTab/CategoryTab';
import useMediaQuery from '../../hooks/useMediaQuery';
import { setIsApiusSpaceOpen } from '../../state/apiusSpaceSlice';
import IsotopeLayout from '../isotopeGrid/IsotopeLayout';
import IsotopeItem from '../isotopeGrid/IsotopeItem';
import LoadMoreButton from '../isotopeGrid/LoadMoreButton';
import NoResultsComponent from './noResultsComponent';

const ease = 'expo.inOut';

const StyledSpaceBarWrapper = styled.div`
    align-items: center;
    backdrop-filter: blur(20px);
    background: linear-gradient(
            90deg,
            #0feac0 2.99%,
            rgba(11, 17, 66, 0.6) 30.98%,
            rgba(151, 71, 255, 0.6) 70.46%,
            rgba(12, 237, 199, 0.6) 98.95%
    );
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 35px;
    justify-content: flex-end;
    left: 0;
    padding: 0 13px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
    @media (min-width: 768px) {
        padding: 0 48px;
        height: 44px;
    }
    @media (min-width: 1024px) {
        height: 59px;
        padding: 0 65px;
    }
    @media (min-width: 1280px) {
        display: none;
    }
`;

const StyledTextBar = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamily.robotoFont};
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: 17px;
    width: max-content;
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
        font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
    @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 23px;
    }
    @media (min-width: 1280px) {
        font-weight: ${({ theme }) => theme.fontWeight.light};
        transform: rotate(-90deg);
        font-size: 16px;
        line-height: 19px;
    }
`;

const StyledTitle = styled.p`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontWeight.montserratFont};
    font-size: 22px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 27px;
    margin-bottom: 22px;
    margin-left: 22px;
    position: relative;
    @media (min-width: 768px) {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 14px;
        margin-left: 48px;
    }
    @media (min-width: 1024px) {
        font-size: 48px;
        line-height: 59px;
        margin-bottom: 18px;
        margin-left: 64px;
    }
    @media (min-width: 1280px) {
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 33px;
        margin-left: 0;
    }

    &:before {
        background-color: ${({ theme }) => theme.colors.green};
        border-radius: 50%;
        bottom: 5px;
        content: '';
        height: 3px;
        position: absolute;
        right: -12px;
        width: 3px;
        @media (min-width: 768px) {
            position: absolute;
            bottom: 5px;
            right: -18px;
        }
        @media (min-width: 1024px) {
            bottom: 10px;
            right: -25px;
            width: 6px;
            height: 6px;
        }
        @media (min-width: 1280px) {
            right: -20px;
        }
    }
`;

const StyledScrollableArea = styled.div`
    height: calc(var(--vh) * 100 - 140px);
    overflow-y: auto;
    padding: 0 22px;

    ::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    scrollbar-width: none;
    width: 100vw;
    @media (min-width: 768px) {
        padding: 0 48px;
    }
    @media (min-width: 1024px) {
        padding: 0 64px;
    }
    @media (min-width: 1280px) {
        padding: 0;
    }
`;

const StyledWrapper = styled.div`
    height: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 25;
    @media (min-width: 1280px) {
        height: calc(var(--vh) * 100);
        width: 0;
		display: none;
    }
`;

const StyledContentContainer = styled.div`
    //padding: 115px 22px 0;
    align-items: flex-start;
    backdrop-filter: blur(20px);
    background: linear-gradient(-15.96deg, #0feac0 -11.36%, rgba(25, 22, 150, 0.7) 49.56%, rgba(25, 18, 175, 0.8) 69.32%, #1a2341 107.33%);
    display: flex;
    flex-direction: column;
    height: calc(var(--vh) * 100);
    justify-content: flex-start;
    left: 0;
    padding: 115px 0 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 35;
    @media (min-width: 768px) {
        padding: 152px 0 0;
    }
    @media (min-width: 1024px) {
        padding: 200px 0 0;
    }
    @media (min-width: 1280px) {
        padding: 90px 115px 0;
        background: linear-gradient(279.29deg, #0feac0 1.44%, rgba(25, 22, 150, 0.5) 33.22%, rgba(25, 18, 175, 0.9) 65.48%, #1a2341 93.89%);
    }
`;

const StyledTopContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledCategoryTabsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: flex-start;
    margin: 20px 0 37px;
    @media (min-width: 768px) {
        margin: 25px 0 45px;
    }
    @media (min-width: 1024px) {
        margin: 33px 0 65px;
    }
    @media (min-width: 1280px) {
        margin: 16px 0 45px;
    }

    p.show-more {
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        margin: auto 0;
        opacity: 0.21;

        &:hover {
            opacity: 0.64;
        }

        @media (min-width: 1024px) {
            font-size: 20px;
            line-height: 23px;
        }
        @media (min-width: 1280px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .counter {
        color: rgba(255, 255, 255, 0.4);
        display: flex;
        flex-direction: column;
        font-family: ${({ theme }) => theme.fontFamily.robotoFont};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.fontWeight.regular};
        justify-content: center;
        line-height: 16px;
        margin-right: 5px;
        @media (min-width: 1024px) {
            font-size: 20px;
            line-height: 23px;
        }
        @media (min-width: 1280px) {
            border-radius: 20px;
            font-size: 14px;
            line-height: 16px;
        }
    }
`;

const StyledNoResultsWrapper = styled.div`
    margin-top: 50px;
    @media (min-width: 1280px) {
        margin-top: 100px;
    }
`;

function ApiusSpaceBarMobile({ articles, spaceData, categories, tags, activeSite, globals, articleDescWordCountArray }) {
	const apiusSpaceBarRef = useRef();
	const textRef = useRef();
	const contentContainerRef = useRef();
	const articlesWrapperRef = useRef();
	const expandedContentRef = useRef();
	const topElementsRef = useRef();
	const titleRef = useRef();
	const isotope = React.useRef();
	const [filterKey, setFilterKey] = React.useState('*');
	const [activeTabKey, setActiveTabKey] = useState([0]);
	const [barActive, setBarActive] = useState(false);
	const numberOfArticles = articles?.length || 1;
	const cardsPerPage = 10;
	const dispatch = useDispatch();
	const isApiusSpaceOpen = useSelector((state) => state.apiusSpace.isOpen);
	const [list, setList] = useState([...articles.slice(0, cardsPerPage)]);
	const [loadMore, setLoadMore] = useState(false);
	const [hasMore, setHasMore] = useState(articles.length > cardsPerPage);
	const [isMobileDevice, setDevice] = useState(true);

	useEffect(() => {
		setDevice(isMobile);
	}, []);

	useEffect(() => {
		setList([...articles.slice(0, cardsPerPage)]);
	}, [activeSite]);
	useEffect(() => {
		if (loadMore && hasMore) {
			const currentLength = list.length;
			const isMore = currentLength < articles.length;
			const nextResults = isMore ? articles.slice(currentLength, currentLength + cardsPerPage) : [];
			setList([...list, ...nextResults]);
			setLoadMore(false);
		}
	}, [loadMore, hasMore, activeSite]);

	useEffect(() => {
		const isMore = list.length < articles.length;
		setHasMore(isMore);
	}, [list]);

	const [numberOfFilteredItems, setNumberOfFilteredItems] = useState(articles.length);
	const horizontalTl = gsap.timeline({ paused: true });
	const isMobileTemp = useMediaQuery('(max-width: 767px)');
	const isSmallTablet = useMediaQuery('(min-width: 768px and max-width: 1023px)');
	const isBigTablet = useMediaQuery('(min-width: 1024px and max-width: 1439px)');
	const isDesktop = useMediaQuery('(min-width: 1280px)');

	// const verticalTl = gsap.timeline({ paused: true });
	const header = spaceData?.header || '';
	const inputPlaceholder = spaceData?.inputPlaceholder || '';
	const readingTime = globals?.readingTime || '';
	const sidebarTextOpen = spaceData?.sidebarTextOpen || '';
	const sidebarTextClose = spaceData?.sidebarTextClose || '';
	const [currentScreenSizeState, setCurrentScreenSize] = useState('isDesktop');

	const handleCountFilteredItems = (number) => {
		setNumberOfFilteredItems(number);
	};

	const updateActiveTabKeys = (key, activeTabKeys) => {
		const removeKey = (array, keyToRemove) => array.filter((item) => item !== keyToRemove);
		const addKey = (array, keyToAdd) => [...array, keyToAdd];

		let updatedKeys = activeTabKeys;
		if (key === 0) {
			updatedKeys = [0];
		} else {
			if (updatedKeys.includes(0)) {
				updatedKeys = removeKey(updatedKeys, 0);
			}
			if (!updatedKeys.includes(key)) {
				updatedKeys = addKey(updatedKeys, key);
			} else {
				updatedKeys = removeKey(updatedKeys, key);
			}
		}
		return updatedKeys;
	};

	const handleTabClick = (className, key) => {
		handleFilterChange(className);
		const updatedActiveTabKeys = updateActiveTabKeys(key, activeTabKey);
		setActiveTabKey(updatedActiveTabKeys);
	};

	const toggleTimeline = () => {
		contentContainerRef.current.reversed(!contentContainerRef.current.reversed());
		dispatch(setIsApiusSpaceOpen(!isApiusSpaceOpen));
		setBarActive(!barActive);
	};

	const handleCloseBar = () => {
		contentContainerRef.current.reversed(true);
	};

	function handleOutsideClick(event) {
		if (
			(!apiusSpaceBarRef.current || !apiusSpaceBarRef.current.contains(event.target)) &&
			!expandedContentRef.current.contains(event.target)
		) {
			toggleTimeline();
		}
	}

	useEffect(() => {
		if (isApiusSpaceOpen) {
			window.addEventListener('click', handleOutsideClick, true);
		} else {
			window.removeEventListener('click', handleOutsideClick, true);
			handleCloseBar();
		}
		return () => {
			window.removeEventListener('click', handleOutsideClick, true);
		};
	}, [isApiusSpaceOpen]);

	const isBarOnTop = useMediaQuery('(max-width: 1279px)');
	useLayoutEffect(() => {
		const mobileAnimation = gsap
			.timeline()
			// .to(expandedContentRef.current, { height: '0', duration: 0.1, ease: 'expo.inOut' })
			.to(textRef.current, { opacity: 1, duration: 0 })
			.to(apiusSpaceBarRef.current, { opacity: 1, duration: 0 })
			.to(expandedContentRef.current, { height: '100vh', duration: 0.8, ease })
			.to(topElementsRef.current, { duration: 0.4, transform: 'translateY(0)' })
			.to(apiusSpaceBarRef.current, { opacity: 1, duration: 0.1 })
			.to(textRef.current, { opacity: 1, duration: 0.1 })
			.reverse();

		const ctx = gsap.context(() => {
			contentContainerRef.current = mobileAnimation;
		});
		return () => ctx.revert();
	}, [contentContainerRef]);

	const combinedTagsAndCategories = useMemo(() => {
		const initArray = activeSite === 'pl' ? [{ name: 'pokaż wszystkie', type: 'tag' }] : [{
			name: 'show all',
			type: 'tag',
		}];
		categories.forEach((item) => {
			initArray.push({
				name: item?.articleCategoryName || '',
				type: 'category',
			});
		});
		tags.forEach((item) => {
			initArray.push({
				name: item?.articleTagName || '',
				type: 'tag',
			});
		});
		return initArray;
	}, [activeSite]);

	const showMobileFiltration = isMobileTemp;
	const [showAllTags, setShowAllTags] = useState(false);
	// categories + show all + first of tags
	const numberOfItemsAlwaysVisible = categories.length + 1 + 1;
	const tagsAlwaysVisible =
		combinedTagsAndCategories.length <= numberOfItemsAlwaysVisible
			? combinedTagsAndCategories
			: combinedTagsAndCategories.slice(0, numberOfItemsAlwaysVisible);
	const restOfTags =
		combinedTagsAndCategories.length <= numberOfItemsAlwaysVisible
			? []
			: combinedTagsAndCategories.slice(numberOfItemsAlwaysVisible, combinedTagsAndCategories.length);
	const numberOfHiddenTags = restOfTags?.length || 0;
	const moreText = activeSite === 'pl' ? 'więcej' : 'more';
	const hideText = activeSite === 'pl' ? 'zwiń' : 'hide';

	const [maxItems, setMaxItems] = useState(cardsPerPage);
	const handleLoadMoreCards = () => {
		setLoadMore(true);
		setMaxItems(maxItems + cardsPerPage);
	};

	const [finalDTSource, setFinalDTSource] = useState([]);
	const handleFinalDataSource = () => {
		if (filterKey === '*' || filterKey === '') {
			setFinalDTSource(list);
		} else {
			setFinalDTSource(articles);
		}
	};

	useEffect(() => {
		handleFinalDataSource();
	}, [filterKey, list, activeSite]);

	const [filters, setFilters] = useState([]);

	const handleFilterChange = (filter) => {
		let updatedFilters;
		if (filter === '*') {
			updatedFilters = [];
		} else if (filters.includes(filter)) {
			updatedFilters = filters.filter((f) => f !== filter);
		} else {
			updatedFilters = [...filters, filter];
		}
		setFilters(updatedFilters);
	};

	return (
		<>
			<StyledSpaceBarWrapper onClick={toggleTimeline} ref={apiusSpaceBarRef}>
				<StyledTextBar ref={textRef}>{!isApiusSpaceOpen ? sidebarTextOpen : sidebarTextClose}</StyledTextBar>
			</StyledSpaceBarWrapper>
			<StyledWrapper ref={expandedContentRef}>
				<StyledContentContainer ref={contentContainerRef}>
					<StyledTitle ref={titleRef}>{header}</StyledTitle>
					<StyledScrollableArea>
						<StyledTopContainer ref={topElementsRef}>
							{showMobileFiltration ? (
								<StyledCategoryTabsWrapper className="mobile-filtration">
									<div className="counter">
										{numberOfFilteredItems}/{numberOfArticles}
									</div>
									{tagsAlwaysVisible.map((item, key) => {
										const classNameDashed = item.name.replace(/\s+/g, '-');
										const classNameForFiltration = key === 0 ? '*' : `.${classNameDashed}`;
										const currentTabClassName =
											key === 0 && activeTabKey.indexOf(key) !== -1
												? 'show-all active-all filter'
												: key === 0 && activeTabKey.indexOf(key) === -1
													? 'show-all filter'
													: activeTabKey.indexOf(key) !== -1
														? `${classNameDashed} active filter`
														: `${classNameDashed} filter`;
										return (
											<CategoryTab
												key={item.name}
												className={currentTabClassName}
												categoryName={item.name}
												handleClick={() => handleTabClick(classNameForFiltration, key)}
												isGradientBackground
											/>
										);
									})}
									{restOfTags.length > 0 && !showAllTags && (
										<p className="show-more" onFocus={() => setShowAllTags(!showAllTags)}
										   onMouseOver={() => setShowAllTags(!showAllTags)}>
											{`+${numberOfHiddenTags} ${moreText}`}
										</p>
									)}
									{showAllTags &&
										restOfTags.length > 0 &&
										restOfTags.map((item, key) => {
											const classNameDashed = item.name.replace(/\s+/g, '-');
											const currentTabClassName =
												activeTabKey.indexOf(key + tagsAlwaysVisible.length) !== -1
													? `${classNameDashed} active filter`
													: `${classNameDashed} filter`;
											return (
												<CategoryTab
													key={item.name}
													className={currentTabClassName}
													handleClick={() =>
														handleTabClick(`.${classNameDashed}`, key + tagsAlwaysVisible.length)
													}
													categoryName={item.name}
													isGradientBackground
												/>
											);
										})}
									{restOfTags.length > 0 && showAllTags && (
										<p className="show-more" onFocus={() => setShowAllTags(!showAllTags)}
										   onMouseOver={() => setShowAllTags(!showAllTags)}>
											{hideText}
										</p>
									)}
								</StyledCategoryTabsWrapper>
							) : (
								<StyledCategoryTabsWrapper>
									<div className="counter">
										{numberOfFilteredItems}/{numberOfArticles}
									</div>
									{combinedTagsAndCategories.map((item, key) => {
										const classNameDashed = item.name.replace(/\s+/g, '-');
										const classNameForFiltration = key === 0 ? '*' : `.${classNameDashed}`;
										const currentTabClassName =
											key === 0 && activeTabKey.indexOf(key) !== -1
												? 'show-all active-all filter'
												: key === 0 && activeTabKey.indexOf(key) === -1
													? 'show-all filter'
													: activeTabKey.indexOf(key) !== -1
														? `${classNameDashed} active filter`
														: `${classNameDashed} filter`;
										return (
											<CategoryTab
												key={item.name}
												className={currentTabClassName}
												categoryName={item.name}
												handleClick={() => handleTabClick(classNameForFiltration, key)}
												isGradientBackground
											/>
										);
									})}
								</StyledCategoryTabsWrapper>
							)}
						</StyledTopContainer>
						<IsotopeLayout
							filters={filters}
							articlesRef={articlesWrapperRef}
							handleLoadMore={handleLoadMoreCards}
							items={finalDTSource}
							handleCount={handleCountFilteredItems}
							barActive={barActive}
							allItems={articles}
						>
							{finalDTSource.map((item, key) => {
								const largeCard = item?.largeCardOnSpace || false;
								const wordCount = articleDescWordCountArray.find((el) => el.id === item.id)?.words || undefined;
								return (
									<IsotopeItem
										data={item}
										activeSite={activeSite}
										readingTime={readingTime}
										largeCard={largeCard}
										key={item.articleName}
										className={item.category}
										wordsCount={wordCount === undefined ? '1' : wordCount}
									/>
								);
							})}
						</IsotopeLayout>
						{numberOfFilteredItems === 0 && (
							<StyledNoResultsWrapper>
								<NoResultsComponent />
							</StyledNoResultsWrapper>
						)}
						{maxItems <= numberOfFilteredItems &&
							<LoadMoreButton text={globals.loadMore} onLoadMore={handleLoadMoreCards} />}
					</StyledScrollableArea>
				</StyledContentContainer>
			</StyledWrapper>
		</>
	);
}

export default ApiusSpaceBarMobile;
