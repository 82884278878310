/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useDispatch } from 'react-redux';
import MenuDNA from '../inlineSvg/MenuDNA';
import MenuNarrow from './MenuNarrow';
import MenuWide from './MenuWide';
import useIsDesktopVersion from '../../hooks/useIsDesktopVersion';
import { setIsMenuOpen } from '../../state/menuSlice';

const Wrapper = styled.div`
	background: ${({ theme, menuType }) =>
		menuType === 'narrow' ? theme.colors.gradients.topGreenToPurple : theme.colors.gradients.topWhiteToGray};
	box-shadow: 0 37px 24px rgba(9, 7, 53, 0.2);
	display: flex;
	height: 100%;
	overflow-x: hidden;
	padding: 0 36px;
	pointer-events: auto;
	position: fixed;
	transform: translateX(110%);
	width: 100%;
	will-change: contents;
	z-index: ${({ theme }) => theme.layers.third + 2};

	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		padding: 0 48px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		padding: 0 75px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		border-radius: 15px;
		bottom: 10px;
		height: unset;
		padding: ${({ menuType }) => (menuType === 'wide' ? '0 63px' : '0 43px')};
		right: 10px;
		top: 10px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		border-radius: 15px;
		bottom: 10px;
		height: unset;
		padding: ${({ menuType }) => (menuType === 'wide' ? '0 63px' : '0 43px')};
		right: 10px;
		top: 10px;
	}
`;
const Context = styled.div`
	height: auto;
	margin: 156px 0 50px;
	width: 100%;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		margin: 28% 0 50px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		margin: 298px 0 65px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		margin: 38px 0 50px;
	}
`;

const ExtendedMenu = memo(({ narrowData, wideData, socials = [], isMenuOpen, menuType, locale = 'pl', menuContexts }) => {
	const isDesktopVersion = useIsDesktopVersion();
	const wrapperRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		gsap.to(wrapperRef.current, {
			x: isMenuOpen ? 0 : '110%',
			ease: 'power3.out',
			width: !isDesktopVersion ? '100%' : menuType === 'wide' ? 'calc(100% - 20px)' : '428px',
			duration: 0.45,
			delay: isMenuOpen ? 0 : 0.45,
			backdropFilter: isMenuOpen ? 'blur(15px)' : 'blur(0px)',
		});
	}, [isMenuOpen, menuType, !isDesktopVersion]);

	function handleOutsideClick(event) {
		if (
			event.target.id !== 'menu-wrapper' &&
			!event.target.classList.contains('menu-button') &&
			!event.target.classList.contains('shuffling-dots') &&
			!wrapperRef.current.contains(event.target)
		) {
			dispatch(setIsMenuOpen(false));
		}
	}

	useEffect(() => {
		if (isMenuOpen) {
			window.addEventListener('click', handleOutsideClick, true);
		} else {
			window.removeEventListener('click', handleOutsideClick, true);
		}
		return () => {
			window.removeEventListener('click', handleOutsideClick, true);
		};
	}, [isMenuOpen]);
	return (
		<Wrapper ref={wrapperRef} menuType={menuType}>
			<MenuDNA isMenuOpen={isMenuOpen} />
			<Context>
				{menuType === 'narrow' ? (
					<MenuNarrow data={narrowData} socials={socials} locale={locale} />
				) : (
					<MenuWide data={wideData} socials={socials} locale={locale} menuContexts={menuContexts} />
				)}
			</Context>
		</Wrapper>
	);
});

export default ExtendedMenu;
