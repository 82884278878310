/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

const offset = -165;

const Svg = styled.svg`
	bottom: 14%;
	margin-left: auto;
	pointer-events: none;
	position: absolute;
	right: 0;
	width: 100%;

	g#dna-wrapper {
		transform: translateX(5%);
	}

	path {
		&.cell-side {
			fill: none;
			opacity: 0.15;
			stroke: #fff;
			stroke-linecap: round;
			stroke-miterlimit: 10;
			stroke-width: 29.48492px;
			transform: rotate(180deg);
			transform-origin: center center;
		}
	}

	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		max-height: 30%;
		g#dna-wrapper {
			transform: translateX(22.5%);
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
		max-height: 30%;
		width: 60vw;
		g#dna-wrapper {
			transform: translateX(70%);
		}
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		max-height: 30%;
		width: 60vw;
		g#dna-wrapper {
			transform: translateX(70%);
		}
	}
`;

const StyledGroup = styled.g`
	stroke-dasharray: 249px;
	transform: ${({ index }) => `translateX(calc(${index}*${offset}px))`};
`;

const Cell = forwardRef(({ index }, ref) => (
	<StyledGroup ref={ref} index={index}>
		<path className="cell-side" d="m14.742 94.493 43.104-59.357c19.746-27.191 60.28-27.191 80.025 0l43.104 59.357" />
		<path
			className="cell-side"
			d="m14.74246,94.49275l43.10386,59.3569c19.74572,27.19118,60.27942,27.19118,80.02514,0l43.10386-59.3569"
		/>
	</StyledGroup>
));

const cells = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

function MenuDNA({ isMenuOpen }) {
	const cellsRefs = useRef([]);

	useEffect(() => {
		gsap.set(cellsRefs.current, { strokeDashoffset: 249 });
	}, []);

	useEffect(() => {
		if (!isMenuOpen) {
			const timeline = gsap.timeline();
			gsap.killTweensOf(cellsRefs.current);
			timeline.to(cellsRefs.current, { strokeDashoffset: 249, stagger: 0.3, reversed: true }).totalDuration(0.5);
		} else {
			const timeline = gsap.timeline();
			timeline.to(cellsRefs.current, { strokeDashoffset: 0, stagger: 0.3 });
		}
	}, [isMenuOpen]);
	return (
		<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 475.71778 239.23521" id="menu-dna">
			<g id="dna-wrapper">
				{cells.map(({ id }) => (
					<Cell key={id} index={id} ref={(ref) => (cellsRefs.current[id] = ref)} />
				))}
			</g>
		</Svg>
	);
}

export default MenuDNA;
