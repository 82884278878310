/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { createRef, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import { useDispatch } from 'react-redux';
import StyledSearchBox from './SearchBox';
import StyledSearchResult from './SearchResults';
import useClickOutside from './use-click-outside';
import { setIsSearchBarOpened } from '../../state/searchBarSlice';
import useMediaQuery from '../../hooks/useMediaQuery';

const innerHeightCssVar = '--inner-height';

const StyledSearchRoot = styled.div`
	opacity: 0;
	padding: 30px 25px 0;
	transition: opacity 0.3s ease-in-out -0.2s;
    @media (min-width: 768px) {
        padding: 30px 0 0;
    }
	@media (min-width: 1280px) {
		margin-top: 0;
        padding: 30px 25px 0;
	}

	mark {
		background-color: transparent;
		color: ${({ theme }) => theme.colors.green} !important;
	}

	.Hits {
		list-style-type: none !important;
	}

	.ais-Hits-list {
		list-style-type: none !important;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
		padding-left: 0 !important;
	}

	&.visible {
		opacity: 1;
		transition: opacity 0.3s ease-in-out 0.4s;
	}
`;

const StyledWrapper = styled.div`
	&.mobile {
		display: block;
		@media (min-width: 1280px) {
			display: none;
		}
	}

	&.desktop {
		display: none;
		@media (min-width: 1280px) {
			display: block;
		}
	}
`;

export default function SearchMain({ indices, handleCloseSearch, activeSite, className, isVisible }) {
	const rootRef = createRef();
	const [query, setQuery] = useState();
	const [hasFocus, setFocus] = useState(false);
	const searchClient = useMemo(() => algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_INDEX_NAME), []);
	const dispatch = useDispatch();
	useClickOutside(rootRef, () => setFocus(false));
	const isDesktop = useMediaQuery('(min-width: 1280px)');

	useEffect(() => {
		if (isVisible) {
			const viewport = window.visualViewport;
			document.documentElement.style.overflow = 'hidden';
			document.documentElement.style.height = `${viewport.height.toString()}px !important`;
			document.documentElement.style.maxHeight = `${viewport.height.toString()}px !important`;
			document.documentElement.style.position = 'fixed';
		} else {
			document.documentElement.style.overflow = '';
			document.documentElement.style.height = '';
			document.documentElement.style.maxHeight = '';
			document.documentElement.style.position = '';
		}
		return () => document.documentElement.style.setProperty(innerHeightCssVar, `${window.innerHeight}px`);
	}, [isVisible]);
	useEffect(() => {
		if (window.visualViewport) {
			window.visualViewport.addEventListener('resize', handleResize);
		}
		return () => window.visualViewport.removeEventListener('resize', handleResize);
	}, []);

	const handleResize = () => {
		if (typeof window.visualViewport) {
			const tempWindowHeight = window?.visualViewport?.height || 0;
			document.documentElement.style.setProperty(innerHeightCssVar, `${tempWindowHeight}px`);
		}
	};

	const handleInputFocus = () => {
		setFocus(true);
		if (typeof window.visualViewport) {
			const tempWindowHeight = window?.visualViewport?.height || 0;
			document.documentElement.style.setProperty(innerHeightCssVar, `${tempWindowHeight}px`);
		}
	};

	useEffect(() => {
		setFocus(true);
	}, [isVisible]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const keyDownHandler = (event) => {
				if (
					event.keyCode === 114 ||
					(event.ctrlKey && event.keyCode === 70) ||
					event.keyCode === 114 ||
					(event.ctrlKey && event.keyCode === 70) ||
					(event.metaKey && event.keyCode === 70)
				) {
					event.preventDefault();
					dispatch(setIsSearchBarOpened(true));
				}
				if (event.keyCode === 27) {
					dispatch(setIsSearchBarOpened(false));
				}
			};
			window.addEventListener('keydown', keyDownHandler);
			return () => window.removeEventListener('keydown', keyDownHandler);
		}
	}, []);

	return (
		<StyledSearchRoot className={className} ref={rootRef}>
			<InstantSearch searchClient={searchClient} indexName={indices[0].name}>
				<StyledWrapper className="mobile">
					<StyledSearchBox
						onChange={(query) => setQuery(query)}
						onFocus={handleInputFocus}
						hasFocus={hasFocus}
						isVisible={isVisible}
						handleCloseSearch={handleCloseSearch}
						placeholder={activeSite === 'pl' ? 'Wyszukaj' : 'Search'}
						isDesktop={isDesktop}
					/>
					{query && query.length > 0 && (
						<StyledSearchResult
							activeSite={activeSite}
							show={query && query.length > 0 && hasFocus}
							indices={indices}
							handleCloseSearch={handleCloseSearch}
						/>
					)}
				</StyledWrapper>
				<StyledWrapper className="desktop">
					{query && query.length > 0 && (
						<StyledSearchResult
							activeSite={activeSite}
							show={query && query.length > 0 && hasFocus}
							indices={indices}
							handleCloseSearch={handleCloseSearch}
						/>
					)}
					<StyledSearchBox
						onChange={(query) => setQuery(query)}
						onFocus={handleInputFocus}
						hasFocus={hasFocus}
						isVisible={isVisible}
						handleCloseSearch={handleCloseSearch}
						placeholder={activeSite === 'pl' ? 'Wyszukaj' : 'Search'}
						isDesktop={isDesktop}
					/>
				</StyledWrapper>
			</InstantSearch>
		</StyledSearchRoot>
	);
}
