/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  
  :root {
    --background-color: ${({ theme }) => theme.colors.gradients.leftBlueToDarkBlue};
    --vh: 1vh;
    // --padding-xs: 15px;
    // --padding-s: 30px;
    // --padding-m: 55px;
    // --padding-l: 70px;
    // --padding-xl: 85px;
      // @media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
    //   --padding-xs: 25px;
    //   --padding-s: 50px;
    //   --padding-m: 75px;
    //   --padding-l: 100px;
    //   --padding-xl: 125px;
    // }
      // @media (min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}),
      // (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape) {
    //   --padding-xs: 25px;
    //   --padding-s: 50px;
    //   --padding-m: 75px;
    //   --padding-l: 100px;
    //   --padding-xl: 125px;
    //}
    --padding-xs: 2vh;
    --padding-s: 5vh;
    --padding-m: 10vh;
    --padding-l: 15vh;
    --padding-xl: 20vh;

    @media (orientation: landscape) {
      --padding-xs: 2.5vw;
      --padding-s: 5vw;
      --padding-m: 7.5vw;
      --padding-l: 9vw;
      --padding-xl: 10.5vw;
    }
  }

  html {
    box-sizing: border-box;
    overflow-y: ${({ isModalWithVideoVisible }) => (isModalWithVideoVisible ? 'hidden' : 'auto')};
    overflow: auto;
  }

  //@media (max-height: 599px) and (orientation: landscape) {
  //  html {
  //    position: fixed;
  //    height: 100%;
  //  }
  //}

  html, body {
    background: var(--background-color);
  }

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: ${({ theme }) => theme.fontFamily.montserratFont};
    -webkit-tap-highlight-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    overflow: hidden;

    #hubspot-messages-iframe-container {
        // z-index: ${({ theme }) => theme.layers.third - 1} !important;
      // right: 6px !important;
      // bottom: 29px !important;
      z-index: 24 !important;
      @media (min-width: 768px) {
        right: 32px !important;
        bottom: 33px !important;
      }
      @media (min-width: 1024px) {
        right: 25px !important;
        bottom: 29px !important;
      }
      @media (min-width: 1440px) {
        right: 18px !important;
        bottom: 19px !important;
      }

    }


    button {
      font-family: ${({ theme }) => theme.fontFamily.montserratFont};

      &:focus {
        outline: none;
      }
    }

    a {
      text-decoration: none;
      color: unset;

      &:focus {
        outline: none;
      }
    }

    *, *::before, *::after {
      box-sizing: border-box;
    }

    button {
      background-color: transparent;
    }


    *::placeholder {
      font-family: ${({ theme }) => theme.fontFamily.montserratFont};
    }

    h1, h2, h3, h4, h5 {
      margin: 0;
      padding: 0;
    }

    h6 {
      display: inherit;
      font-size: inherit;
      margin: 0;
      padding: 0;
      font-weight: inherit;
    }

    p {
      margin: 0;
      padding: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .green {
      color: ${({ theme }) => theme.colors.primaryGreen};
    }

    .blue {
      color: ${({ theme }) => theme.colors.primaryBlue};
    }

    .white {
      color: ${({ theme }) => theme.colors.white};
    }

    .light {
      font-weight: ${({ theme }) => theme.fontWeight.light};
    }

    .bold {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }

    .underlineHover {
      position: relative;

      &:before {
        bottom: -3px;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.25s ease-in-out, background 0.25s ease-in-out;
        width: 100%;
        background: ${({ theme }) => theme.colors.white};
      }

      &:hover {
        &:before {
          transform: scaleX(1);
        }
      }
    }

    .animate {
      opacity: 0;
      translate: 0 20%;
      transition: opacity 0.5s ease-out 0.25s, translate 0.5s ease-out 0.25s;
      will-change: opacity, translate;

      @media (min-width: 1024px) and (orientation: landscape) {
        translate: 0 100px;
      }
      @media (min-width: 1440px) {
        translate: 0 100px;
      }
    }

    .fade-in {
      opacity: 1;
      translate: 0 0;
    }

    .transition-delay-short {
      transition-delay: 0.5s;
    }

    .transition-delay-long {
      transition-delay: 0.75s;
    }

    .padding-top-xs {
      padding-top: var(--padding-xs);
    }

    .padding-bottom-xs {
      padding-bottom: var(--padding-xs);
    }

    .padding-top-s {
      padding-top: var(--padding-s);
    }

    .padding-bottom-s {
      padding-bottom: var(--padding-s);
    }

    .padding-top-m {
      padding-top: var(--padding-m);
    }

    .padding-bottom-m {
      padding-bottom: var(--padding-m);
    }

    .padding-top-l {
      padding-top: var(--padding-l);
    }

    .padding-bottom-l {
      padding-bottom: var(--padding-l);
    }

    .padding-top-xl {
      padding-top: var(--padding-xl);
    }

    .padding-bottom-xl {
      padding-bottom: var(--padding-xl);
    }
  }
`;

export default GlobalStyle;
