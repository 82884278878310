/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';
import styled from 'styled-components';

const StyledCategoryTabWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme, isGradientBackground }) =>
		isGradientBackground ? theme.colors.spaceCategoryTagBar : theme.colors.spaceCategoryTagPage};
	border-radius: 55px;
	color: #090735;
	display: flex;
	flex-direction: row;
	font-family: ${({ theme }) => theme.fontFamily.robotoFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontFamily.regular};
	gap: 10px;
	line-height: 16px;
	padding: 5px 18px;
	position: relative;
	transition: padding 0.25s ease-out, opacity 0.2s ease-in-out;
	&.filter {
		opacity: 0.44;
	}
	@media (min-width: 1280px) {
		&:hover {
			background-color: ${({ theme, isGradientBackground }) =>
				isGradientBackground ? theme.colors.spaceCategoryTagBarHover : theme.colors.spaceCategoryTagPageHover};
			cursor: ${({ slider, infoOnly }) => (slider ? 'none' : infoOnly ? 'default' : 'pointer')};
			opacity: 1;
		}
	}
	&.from-the-life-of-the-company,
	&.z-życia-firmy {
		background-color: ${({ theme }) => theme.colors.spaceCategoryCompanyLife};
		@media (min-width: 1280px) {
			&:hover {
				background-color: ${({ theme, infoOnly }) =>
					infoOnly ? theme.colors.spaceCategoryCompanyLife : theme.colors.spaceCategoryCompanyLifeHover};
			}
		}
	}
	&.case-study,
	&.wdrożenia {
		background-color: ${({ theme }) => theme.colors.spaceCategoryCaseStudy};
		@media (min-width: 1280px) {
			&:hover {
				background-color: ${({ theme, infoOnly }) =>
					infoOnly ? theme.colors.spaceCategoryCaseStudy : theme.colors.spaceCategoryCaseStudyHover};
			}
		}
	}
	&.partner {
		background-color: ${({ theme }) => theme.colors.spaceCategoryPartner};
		color: ${({ theme }) => theme.colors.white};
		@media (min-width: 1280px) {
			&:hover {
				background-color: ${({ theme, infoOnly }) =>
					infoOnly ? theme.colors.spaceCategoryPartner : theme.colors.spaceCategoryPartnerHover};
			}
		}
	}
	&.events,
	&.wydarzenia {
		background-color: ${({ theme }) => theme.colors.spaceCategoryEvents};
		color: ${({ theme }) => theme.colors.white};
		@media (min-width: 1280px) {
			&:hover {
				background-color: ${({ theme, infoOnly }) =>
					infoOnly ? theme.colors.spaceCategoryEvents : theme.colors.spaceCategoryEventsHover};
			}
		}
	}
	&.pokaż-wszystkie,
	&.show-all {
		background-color: transparent;
		border: none;
		color: rgba(255, 255, 255, 0.4);
		padding: 0;
		text-decoration-line: underline;
		&:hover {
			color: rgba(255, 255, 255, 0.64);
		}
	}
	span {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		opacity: 0;
		position: absolute;
		right: 18px;
		transition: opacity 0.2s ease-out;
	}
	&.active {
		opacity: 1;
		padding-right: 30px;
		transition: padding 0.25s ease-out;
		span {
			opacity: 1;
			transition: opacity 0.25s ease-out 0.15s;
		}
	}
	&.active-all {
		opacity: 1;
		transition: opacity 0.25s ease-out;
	}
	@media (min-width: 1024px) {
		font-size: 20px;
		line-height: 23px;
	}
	@media (min-width: 1280px) {
		border-radius: 20px;
		font-size: 14px;
		line-height: 16px;
	}
`;

function CategoryTab({ className, categoryName, isGradientBackground, handleClick, slider, infoOnly }) {
	return (
		<StyledCategoryTabWrapper
			onClick={handleClick}
			className={className}
			isGradientBackground={isGradientBackground}
			slider={slider}
			infoOnly={infoOnly}
		>
			<p>{categoryName}</p>
			<span>x</span>
		</StyledCategoryTabWrapper>
	);
}

export default CategoryTab;
