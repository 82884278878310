/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { isIOS, isMacOs } from 'react-device-detect';
import LoupeIcon from '../inlineSvg/LoupeIcon';

const StyledButtonWrapper = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.green};
	display: flex;
	flex-direction: row;
	font-family: ${({ theme }) => theme.fontFamily.robotoFont};
	font-size: 13px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	justify-content: flex-start;
	line-height: 15px;

	&:hover {
		cursor: pointer;
	}

	p {
		margin-right: 10px;
	}

	svg {
		height: 11px;
		width: 11px;
	}

	@media (min-width: 768px) {
		border-radius: 6px;
		padding: 13px 50px 13px 25px;
		font-size: 18px;
		line-height: 21px;
		p {
			margin-right: 15px;
		}
	}
	@media (min-width: 1024px) {
		padding: 16px 52px 16px 28px;
		font-size: 20px;
		line-height: 23px;
		p {
			margin-right: 20px;
		}
	}
	@media (min-width: 1280px) {
		padding: 0;
		font-size: 13px;
		line-height: 15px;
		p {
			margin-right: 15px;
		}

		&:hover {
			.search-text {
				width: 150px;
				transition: width 0.3s ease-in-out;
				height: 16px;
			}
		}
	}
`;

const StyledTextContainer = styled.div`
	height: 24px;
	overflow: auto;
	position: relative;
	transition: width 0.3s ease-in-out;
	width: 0;
	@media (min-width: 1280px) {
		width: 200px;
		overflow: hidden;
		transition: width 0.3s ease-in-out;
		position: relative;
		height: 16px;
	}

	p {
		position: absolute;
		right: 0;
		text-align: right;
		top: 0;
		width: 150px;
	}
`;

function SearchButton({ text }) {
	const [hovered, setHover] = useState(false);
	const hoverText = isIOS || isMacOs ? '⌘ + F' : 'Ctrl + F';
	const handleMouseOver = () => {
		setHover(true);
	};
	const handleMouseOut = () => {
		setHover(false);
	};
	return (
		<StyledButtonWrapper onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
			<StyledTextContainer className="search-text">
				<p>{hovered ? hoverText : text}</p>
			</StyledTextContainer>
			<LoupeIcon />
		</StyledButtonWrapper>
	);
}

export default SearchButton;
