/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';

const ArrowShortIcon = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox={`0 0 16 12`}
			{...props}
		>
			<path
				fill="#110E56"
				d="M7.293 12.707a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414L8 10.586 2.343 4.929A1 1 0 0 0 .93 6.343l6.364 6.364ZM7 0v12h2V0H7Z"
			/>
		</svg>
	);
};

export default ArrowShortIcon;