/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
	name: 'language',
	initialState: 'pl',
	reducers: {
		setLang: (state, action) => action.payload,
	},
});

export const { setLang } = languageSlice.actions;

export default languageSlice.reducer;
