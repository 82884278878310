/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { configureStore } from '@reduxjs/toolkit';
import language from './languageSlice';
import menu from './menuSlice';
import apiusSpace from './apiusSpaceSlice';
import colorScheme from './colorSchemeSlice';
import DNA from './DNASlice';
import searchBar from './searchBarSlice';

export default configureStore({
	reducer: {
		language,
		menu,
		apiusSpace,
		colorScheme,
		DNA,
		searchBar,
	},
});
