/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import ArrowButton from '../arrowButton/ArrowButton';
import ArrowShortIcon from '../inlineSvg/ArrowShortIcon';
import { getServicePageSlug, getSlug } from '../../utils/getSlug';
import { Bottom } from './MenuNarrow';
import GhostArrowButton from '../common/GhostArrowButton/GhostArrowButton';
import { setIsMenuOpen } from '../../state/menuSlice';
import useIsDesktopVersion from '../../hooks/useIsDesktopVersion';
import { setIsSearchBarOpened } from '../../state/searchBarSlice';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
`;
const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 15px 25px;
	height: 100%;
	overflow-y: scroll;
	//scrollbar hide
	::-webkit-scrollbar {
		display: none;
		width: 0;
	}

	//mozilla scrollbar hide
	scrollbar-width: none;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		gap: 20px 25px;
	}

	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		display: grid;
		grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
		margin-bottom: unset;
	}
`;
const ColumnContent = styled.div`
	display: flex;
	flex-direction: column;
`;
const Header = styled.p`
	align-items: center;
	color: ${({ theme }) => theme.colors.menuServicesColor};
	display: flex;
	font-size: 24px;
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	justify-content: space-between;
	line-height: 29px;
	margin-bottom: 10px;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 15px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		font-size: 40px;
		line-height: 48px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 10px;
	}
`;
const ItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
	opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
	overflow: hidden;
	transform-origin: top center;
	transition: all 0.5s ease-in-out;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		max-height: unset;
		opacity: 1;
	}
`;
const Item = styled(Link)`
	border-bottom: 1px solid ${({ theme }) => theme.colors.menuServicesBorderBottom};
	border-left: 2px solid transparent;
	color: ${({ theme }) => theme.colors.menuServicesColor};
	cursor: pointer;
	font-family: ${({ theme }) => theme.fontFamily.robotoFont};
	font-size: 13px;
	font-weight: ${({ theme }) => theme.fontWeight.medium};
	letter-spacing: 0.03em;
	padding: 13px 10px;
	text-transform: uppercase;
	transition: all 0.25s ease-in-out;

	&.active {
		background: ${({ theme }) => theme.colors.gradients.leftWhiteToTransparent};
		border-left: 2px solid #0cedc7;
	}

	&:hover {
		background: rgba(201, 255, 246, 1);
	}

	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: 15px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		font-size: 20px;
		padding: 18px 10px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: 13px;
		padding: 14px 10px;
	}
`;
const ContactUs = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin-top: auto;
	position: relative;
	row-gap: 15px;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		max-width: unset;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		align-items: end;
		align-self: end;
		bottom: 0;
		justify-content: end;
		margin-bottom: 24px;
		margin-top: auto;
		max-width: 294px;
		position: absolute;
		right: 0;
	}
`;
const Paragraph = styled.p`
	color: ${({ theme }) => theme.colors.menuServicesColor};
	font-size: 20px;
	line-height: 24px;
	text-align: start;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: 28px;
		line-height: 34px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: 24px;
		line-height: 29px;
		text-align: end;
	}
`;
const StyledArrowShort = styled(ArrowShortIcon).withConfig({
	shouldForwardProp: (prop) => prop !== 'isOpen',
})`
	width: 10px;
	height: 10px;
	display: block;
	transform: ${({ isOpen }) => (isOpen ? 'rotateX(180deg)' : 'rotate(0deg)')};
	transform-origin: center center;
	transition: transform 0.3s ease-in-out;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		width: 14px;
		height: 14px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		width: 16px;
		height: 16px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		display: none;
	}
`;
const BottomItem = styled.span`
	color: ${({ theme }) => theme.colors.menuServicesColor};
	font-size: 12px;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	@media (min-width: ${({ theme }) => `${theme.breakpoints.smallTablet}px`}) {
		font-size: 16px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) {
		font-size: 16px;
	}
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		font-size: 12px;
	}

	${({ grow }) => grow && 'flex-grow: 2;'}
	a {
		transition: font-weight 0.25s ease-in-out;
	}

	a:hover {
		font-weight: ${({ theme }) => theme.fontWeight.black};
	}

	&.search {
		display: block;
		@media (min-width: 1280px) {
			display: none;
		}
	}

	&.underlineHover {
		&:before {
			background-color: ${({ theme }) => theme.colors.menuServicesColor};
		}
	}
`;

const AllServicesButton = styled(GhostArrowButton)`
	margin-bottom: 24px;
	margin-top: -34px;
	@media (min-width: ${({ theme }) => `${theme.breakpoints.tablet}px`}) and (orientation: landscape),
		(min-width: ${({ theme }) => `${theme.breakpoints.desktop}px`}) {
		margin-top: 0;
		margin-bottom: 60px;
	}
`;

const categories = ['cybersecurity', 'cloud', 'network', 'services'];

const handleIsOpen = (key, setter) => {
	setter((prevState) =>
		Object.keys(prevState).reduce((acc, curKey) => {
			acc[curKey] = curKey === key ? !prevState[curKey] : false;
			return acc;
		}, {}),
	);
};

function Column({
	items = [],
	headerText = 'Column',
	isOpen = false,
	setIsOpen = () => console.log('No function applied'),
	category,
	locale,
	children,
}) {
	const dispatch = useDispatch();
	return (
		<ColumnContent>
			<Header
				key={headerText}
				onClick={() => {
					setIsOpen();
				}}
			>
				{headerText}
				<StyledArrowShort isOpen={isOpen} />
			</Header>
			<ItemsWrapper isOpen={isOpen}>
				{items.map((item) => {
					const { title, id, link } = item;
					return (
						<Item
							key={id}
							activeClassName="active"
							to={getServicePageSlug(link.slug, category, locale)}
							onClick={() => {
								dispatch(setIsMenuOpen(false));
							}}
						>
							{title}
						</Item>
					);
				})}
			</ItemsWrapper>
			{children}
		</ColumnContent>
	);
}

function MenuWide({ data = [], defaultColumnHeader = 'Column', locale, socials, menuContexts }) {
	const [isOpen, setIsOpen] = useState({
		col1: false,
		col2: false,
		col3: false,
		col4: false,
	});
	const menuData = useMemo(
		() => data.map((node) => ({ ...node, treeChildren: node.treeChildren.sort((a, b) => a.position - b.position) })),
		[data],
	);
	const menuIsOpen = useSelector((state) => state.menu.isOpen);
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	const isDesktopVersion = useIsDesktopVersion();
	const ColumnWrapperRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		if (menuIsOpen) ColumnWrapperRef.current.scrollTop = 0;
		if (!menuIsOpen) {
			setIsOpen({
				col1: false,
				col2: false,
				col3: false,
				col4: false,
			});
		}
	}, [menuIsOpen]);
	return (
		<Wrapper>
			<AllServicesButton
				onClick={() => {
					navigate(getSlug(locale === 'pl' ? 'uslugi' : 'services', locale));
					dispatch(setIsMenuOpen(false));
				}}
			>
				{menuContexts.allServices}
			</AllServicesButton>
			<ColumnWrapper ref={ColumnWrapperRef}>
				{menuData.map((item, index) => (
					<Column
						key={item.id}
						headerText={item.title || defaultColumnHeader}
						isOpen={isOpen[`col${index + 1}`]}
						setIsOpen={() => handleIsOpen(`col${index + 1}`, setIsOpen)}
						items={item.treeChildren || []}
						category={categories[index]}
						locale={locale}
					/>
				))}
				<ContactUs>
					<Paragraph>{menuContexts.contact}</Paragraph>
					<ArrowButton
						text={menuContexts.buttonBig}
						smallText={menuContexts.buttonSmall}
						variant="special"
						link={getSlug(locale === 'pl' ? 'kontakt' : 'contact', locale)}
						activeSite={locale}
					/>
				</ContactUs>
			</ColumnWrapper>
			<Bottom>
				<BottomItem className="search" small bold grow onClick={() => dispatch(setIsSearchBarOpened(!isSearchBarVisible))}>
					{locale === 'pl' ? 'szukaj' : 'search'}
				</BottomItem>
				{socials.map((social) => {
					const { title, slug, id } = social;
					return (
						<BottomItem key={id} className="underlineHover">
							<a href={slug || '/'} target="_blank" rel="noreferrer">
								{title || ''}
							</a>
						</BottomItem>
					);
				})}
			</Bottom>
		</Wrapper>
	);
}

export default memo(MenuWide);
