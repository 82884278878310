import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ApiusSpaceBar from './ApiusSpaceBar';
import ApiusSpaceBarMobile from './ApiusSpaceBarMobile';
import useMediaQuery from '../../hooks/useMediaQuery';
import { setIsApiusSpaceOpen } from '../../state/apiusSpaceSlice';

function ApiusSpaceBarWrapper({
								  articles,
								  spaceData,
								  categories,
								  tags,
								  activeSite,
								  globals,
								  articleDescWordCountArray,
							  }) {
	const isDesktop = useMediaQuery('(min-width: 1280px)');
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setIsApiusSpaceOpen(false));
	}, [isDesktop]);
	return (
		<>
			{!isDesktop && <ApiusSpaceBarMobile
				articles={articles}
				spaceData={spaceData}
				categories={categories}
				tags={tags}
				activeSite={activeSite}
				globals={globals}
				articleDescWordCountArray={articleDescWordCountArray}
			/> }
			{isDesktop && <ApiusSpaceBar
				articles={articles}
				spaceData={spaceData}
				categories={categories}
				tags={tags}
				activeSite={activeSite}
				globals={globals}
				articleDescWordCountArray={articleDescWordCountArray}
			/>}
		</>
	);
}

export default ApiusSpaceBarWrapper;
