/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

const getHtmlFromRedactorField = field => {
	if (!field) {
		return null;
	}
	return {__html: `<div class="custom-redactor" x-ms-format-detection="none">${field}</div>`};
};

export default getHtmlFromRedactorField;
