/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { memo, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useSelector } from 'react-redux';
import LogoApius from '../inlineSvg/LogoApius';

function Logo({ locale = 'pl', isSticky, ...props }) {
	const { scroll } = useLocomotiveScroll();
	const [isSignet, setIsSignet] = useState(false);
	const apiusSpace = useSelector((state) => state.apiusSpace);
	const colorScheme = useSelector((state) => state.colorScheme);
	const handleIsSignet = (scroll) => {
		const {
			scroll: { y },
		} = scroll;
		if (y > 60) setIsSignet(true);
		else setIsSignet(false);
	};
	useEffect(() => {
		if (scroll) {
			scroll.on('scroll', handleIsSignet);
			return () => scroll.off('scroll', handleIsSignet);
		}
	}, [scroll]);
	return (
		<LogoApius
			isWhite={apiusSpace.isOpen}
			colorScheme={colorScheme}
			hidden={isSignet || apiusSpace.isOpen}
			onClick={() => {
				navigate(locale === 'pl' ? '/' : '/en');
			}}
			isSticky={isSticky}
			{...props}
		/>
	);
}

export default memo(Logo);
