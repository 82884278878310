/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import FooterTextAndTitleBlock from './contentBlocks/FooterTextAndTitleBlock';
import FooterText from '../typography/FooterText';
import FooterTitleAndLinksBlock from './contentBlocks/FooterTitleAndLinksBlock';

const StyledFooterWrapper = styled.div`
	background: rgba(14, 13, 54, 1);
	display: block;
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-size: 13px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	height: auto;
	line-height: 24px;
	padding: 51px 23px 65px;
	position: relative;
	width: 100vw;
	z-index: 1000;
	@media (min-width: 768px) {
		//padding: 50px 65px 56px;
		padding: 50px 65px 45px;
	}
	@media (min-width: 1024px) {
		padding: 65px 50px 80px;
	}
	@media (min-width: 1440px) {
		padding: 50px 65px 30px 115px;
	}
`;

const StyledApiusAndServicesContainer = styled.div`
	display: block;
	overflow: hidden;
	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
	}
	@media (min-width: 1440px) {
		flex-direction: row;
		row-gap: 50px;
	}
`;

const StyledServicesWrapper = styled.div`
	display: block;
	width: 100%;
	@media (min-width: 768px) {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	@media (min-width: 1440px) {
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 80%;
	}
`;

const StyledTextsWrapper = styled.div`
	display: block;
	margin-top: 20px;
	width: 100%;
	@media (min-width: 768px) {
		margin-top: 25px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		div.text-block {
			&:first-child,
			&:nth-child(2) {
				width: 40%;
			}

			&:nth-child(3) {
				width: 20%;
			}

			@media (min-width: 1440px) {
				&:first-child,
				&:nth-child(2),
				&:nth-child(3) {
					width: 20%;
				}
			}
		}
	}
	@media (min-width: 1440px) {
		justify-content: flex-start;
		row-gap: 50px;
	}
`;

const StyledPolicySocialMediaTextsWrapper = styled.div`
	display: block;
	width: 100%;
	@media (min-width: 768px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-content: flex-end;
	}
`;

const StyledSocialMediaAndTextsWrapper = styled.div`
	display: block;
	width: 100%;
	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 60%;
	}
	@media (min-width: 1280px) {
		width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		margin-top: 40px;
		//justify-content: space-between;
	}
	@media (min-width: 1440px) {
		margin-top: 0;
	}
`;

const StyledShowHideButtonWrapper = styled.div`
	display: flex;
	margin-bottom: 40px;
	position: relative;
`;

const StyledSitemapTextWrapper = styled.div`
	cursor: pointer;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity 0.3s ease-in-out;

	&.active {
		opacity: 1;
		transition: opacity 0.4s ease-in-out;
	}
`;

const StyledDotsWrapper = styled.div`
	bottom: -11px;
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: flex-start;
	left: ${({ activeSite }) => (activeSite === 'pl' ? '135px' : '100px')};
	position: absolute;
	@media (min-width: 1024px) {
		left: ${({ activeSite }) => (activeSite === 'pl' ? '175px' : '130px')};
		bottom: -12px;
	}
	@media (min-width: 1440px) {
		left: ${({ activeSite }) => (activeSite === 'pl' ? '135px' : '100px')};
		bottom: -10px;
	}

	div {
		transition: transform 0.3s ease-in-out;
	}

	&:hover,
	&.animate {
		div {
			&:first-child {
				transform: translateX(-10px);
			}

			&:nth-child(2) {
				transform: translateX(-7px);
			}

			&:nth-child(3) {
				transform: translateX(-4px);
			}

			&:last-child {
				transform: translateX(8px);
			}
		}
	}
`;

const StyledSingleDot = styled.div`
	border-radius: 50%;
	height: 5px;
	margin-right: 2px;
	width: 5px;

	&.green {
		background-color: ${({ theme }) => theme.colors.green};
	}

	&.blue {
		background-color: ${({ theme }) => theme.colors.footerCloudBlue};
	}

	&.violet {
		background-color: ${({ theme }) => theme.colors.footerNetworkViolet};
	}

	&.yellow {
		background-color: ${({ theme }) => theme.colors.footerServicesYellow};
	}
`;

const StyledSocialMediaWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 20px;
	@media (min-width: 768px) {
		margin-bottom: 37px;
		margin-top: 54px;
	}
	@media (min-width: 1024px) {
		margin-top: 0;
		margin-bottom: 34px;
	}
	@media (min-width: 1280px) {
		margin-top: 0;
		margin-bottom: 20px;
	}
`;

const StyledSingleSocialMediaItem = styled.a`
	text-decoration-color: ${({ theme }) => theme.colors.footerGray};
	text-decoration-line: underline;
	text-decoration-thickness: 1px;
	text-transform: uppercase;

	&:first-child {
		margin-right: 27px;
	}
`;

const StyledBottomTextsWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	> div {
		&:first-child {
			margin-bottom: 14px;
		}
	}

	@media (min-width: 768px) {
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}
	@media (min-width: 1024px) {
		padding-right: 30px;
	}
	@media (min-width: 1280px) {
		width: 60%;
		flex-direction: row-reverse;
	}
`;

const StyledPolicyMobileWrapper = styled.div`
	display: block;
	@media (min-width: 1280px) {
		display: none;
	}
`;

const StyledPolicyDesktopWrapper = styled.div`
	display: none;
	@media (min-width: 1280px) {
		display: block;
		width: 20%;
	}
`;

function Footer({ data, globals, activeSite }) {
	const showSitemapText = data?.showSitemap || '';
	const hideSitemapText = data?.hideSitemap || '';
	const apiusSignature = data?.apiusSignature || '';
	const designedAndDeveloped = data?.designedAndDeveloped || '';
	const blocksOfContent = data?.chooseBlocksOfContent || [];
	const socialItems = globals?.socialsItems || [];
	const apiusBlockData = [];
	const serviceBlockData = [];
	const titleAndTextBlockData = [];
	const policyBlockData = [];
	const [isSitemapVisible, setIsSitemapVisible] = useState(false);
	const toggleSitemapVisibility = () => {
		setIsSitemapVisible(!isSitemapVisible);
	};

	blocksOfContent.map((item) => {
		const typename = item?.__typename || '';
		if (typename === 'DatoCmsFooterApiusBlock') {
			apiusBlockData.push(item);
		} else if (typename === 'DatoCmsFooterServiceBlock') {
			serviceBlockData.push(item);
		} else if (typename === 'DatoCmsFooterTitleAndTextBlock') {
			titleAndTextBlockData.push(item);
		} else if (typename === 'DatoCmsFooterPolicyBlock') {
			policyBlockData.push(item);
		}
	});

	return (
		<StyledFooterWrapper>
			<StyledShowHideButtonWrapper onClick={toggleSitemapVisibility}>
				<StyledSitemapTextWrapper className={!isSitemapVisible ? 'active' : ''}>
					<FooterText>{showSitemapText}</FooterText>
				</StyledSitemapTextWrapper>
				<StyledSitemapTextWrapper className={isSitemapVisible ? 'active' : ''}>
					<FooterText>{hideSitemapText}</FooterText>
				</StyledSitemapTextWrapper>
				<StyledDotsWrapper className={isSitemapVisible ? 'animate' : ''} activeSite={activeSite}>
					<StyledSingleDot className="green" />
					<StyledSingleDot className="blue" />
					<StyledSingleDot className="violet" />
					<StyledSingleDot className="yellow" />
				</StyledDotsWrapper>
			</StyledShowHideButtonWrapper>
			<StyledApiusAndServicesContainer>
				{apiusBlockData.map((item) => {
					const title = item?.title || '';
					const type = 'apius';
					return (
						<FooterTitleAndLinksBlock
							key={title}
							type={type}
							data={item}
							activeSite={activeSite}
							isSitemapVisible={isSitemapVisible}
							toggleSitemapVisibility={toggleSitemapVisibility}
						/>
					);
				})}
				<StyledServicesWrapper>
					{serviceBlockData.map((item) => {
						const title = item?.title || '';
						const type = item?.serviceCategory || '';
						return (
							<FooterTitleAndLinksBlock
								key={title}
								type={type}
								data={item}
								activeSite={activeSite}
								isSitemapVisible={isSitemapVisible}
								toggleSitemapVisibility={toggleSitemapVisibility}
							/>
						);
					})}
				</StyledServicesWrapper>
			</StyledApiusAndServicesContainer>
			<StyledTextsWrapper>
				{titleAndTextBlockData.map((item, key) => {
					const title = item?.title || '';
					const isLongClause = key === 3;
					return <FooterTextAndTitleBlock key={title} data={item} activeSite={activeSite} isLongClause={isLongClause} />;
				})}
				<StyledPolicyDesktopWrapper>
					{policyBlockData.map((item) => {
						const title = item?.title || '';
						const type = 'policy';
						return (
							<FooterTitleAndLinksBlock
								key={title}
								type={type}
								data={item}
								activeSite={activeSite}
								isSitemapVisible={isSitemapVisible}
							/>
						);
					})}
				</StyledPolicyDesktopWrapper>
			</StyledTextsWrapper>
			<StyledPolicySocialMediaTextsWrapper>
				<StyledPolicyMobileWrapper>
					{policyBlockData.map((item) => {
						const title = item?.title || '';
						const type = 'policy';
						return (
							<FooterTitleAndLinksBlock key={title} type={type} data={item} activeSite={activeSite} isSitemapVisible={true} />
						);
					})}
				</StyledPolicyMobileWrapper>
				<StyledSocialMediaAndTextsWrapper>
					<StyledSocialMediaWrapper>
						{socialItems.map((item) => {
							const link = item?.slug || '';
							const title = item?.title || '';
							return (
								<StyledSingleSocialMediaItem key={item.id || ''} href={link} target="_blank">
									<FooterText className="gray">{title}</FooterText>
								</StyledSingleSocialMediaItem>
							);
						})}
					</StyledSocialMediaWrapper>
					<StyledBottomTextsWrapper>
						<FooterText className="gray">{apiusSignature}</FooterText>
						<a
							href={activeSite === 'pl' ? 'https://pleodigital.com/' : 'https://pleodigital.com/en'}
							target="_blank"
							rel="noreferrer"
						>
							<FooterText className="gray designed-and-developed">{designedAndDeveloped}</FooterText>
						</a>
					</StyledBottomTextsWrapper>
				</StyledSocialMediaAndTextsWrapper>
			</StyledPolicySocialMediaTextsWrapper>
		</StyledFooterWrapper>
	);
}

export default Footer;
