/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { createSlice } from '@reduxjs/toolkit';

export const searchBarSlice = createSlice({
	name: 'searchBar',
	initialState: {
		isOpen: false,
	},
	reducers: {
		setIsSearchBarOpened: (state, action) => {
			state.isOpen = action.payload;
		},
	},
});

export const { setIsSearchBarOpened } = searchBarSlice.actions;

export default searchBarSlice.reducer;
