/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';
import { Provider } from 'react-redux';
import Layout from './src/layout/Layout';
import store from './src/state/store';
import './src/styles/globalFonts.css';

export const wrapPageElement = ({ element, props }) => (
	<Provider store={store}>
		<Layout {...props}>{element}</Layout>
	</Provider>
);
