/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';
import SingleArticleCard from '../singleArticleCard/SingleArticleCard';

function IsotopeItem({ data, activeSite, readingTime, largeCard, wordsCount }) {
	return (
		<SingleArticleCard data={data} activeSite={activeSite} readingTime={readingTime} largeCard={largeCard} wordsCount={wordsCount} />
	);
}

export default IsotopeItem;
