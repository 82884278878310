/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';
import Eye from '../../assets/images/eye.svg';
import styled from 'styled-components';

const StyledNoResultsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	img {
		width: 55px;
		height: 36px;
	}
	p {
		font-family: ${({ theme }) => theme.fontFamily.montserratFont};
		color: ${({ theme }) => theme.colors.white};
	}
	p.header {
		margin: 15px 0 10px;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		font-size: 24px;
		line-height: 29px;
	}
	p.description {
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		font-size: 16px;
		line-height: 28px;
		text-align: center;
	}
`;

const NoResultsComponent = () => {
	return (
		<StyledNoResultsWrapper>
			<img src={Eye} alt={'eye'} />
			<p className="header">Brak wyników</p>
			<p className="description">
				Przykro nam, nie znaleźliśmy tego czego szukasz. <br />
				Spróbuj ponownie
			</p>
		</StyledNoResultsWrapper>
	);
};

export default NoResultsComponent;
