/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const StyledParagraph = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	font-size: 13px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 24px;
	&.gray,
	&.policy {
		color: ${({ theme }) => theme.colors.footerGray};
	}
	&.cybersecurity {
		color: ${({ theme }) => theme.colors.green};
	}
	&.cloud {
		color: ${({ theme }) => theme.colors.footerCloudBlue};
	}
	&.network {
		color: ${({ theme }) => theme.colors.footerNetworkViolet};
	}
	&.services {
		color: ${({ theme }) => theme.colors.footerServicesYellow};
	}
	&.designed-and-developed {
		@media (min-width: 768px) {
			position: absolute;
			left: 65px;
			//bottom: 20px;
			//bottom: 0;
		}
		@media (min-width: 1024px) {
			left: 50px;
			position: static;
		}
		@media (min-width: 1280px) {
			position: static;
		}
	}
	@media (min-width: 1024px) {
		font-size: 17px;
		line-height: 26px;
	}
	@media (min-width: 1280px) {
		font-size: 13px;
		line-height: 20px;
	}
`;

const FooterText = ({ className, children, ...props }) => {
	return <StyledParagraph className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} {...props} />;
};

export default FooterText;
