/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';

function LoupeIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="none" viewBox="0 0 9 9">
			<path
				fill="#0CEDC7"
				d="M8.024 9L5.372 6.348A3.463 3.463 0 010 3.461a3.463 3.463 0 016.923 0c0 .707-.215 1.357-.575 1.904L9 8.024 8.024 9zM5.538 3.462a2.083 2.083 0 00-2.076-2.077 2.083 2.083 0 00-2.077 2.077c0 1.142.934 2.076 2.077 2.076a2.083 2.083 0 002.076-2.076z"
			></path>
		</svg>
	);
}

export default LoupeIcon;
