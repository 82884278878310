/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { createSlice } from '@reduxjs/toolkit';

export const colorScheme = createSlice({
	name: 'theme',
	initialState: 'dark',
	reducers: {
		setColorScheme: (state, action) => action.payload,
	},
});

export const { setColorScheme } = colorScheme.actions;

export default colorScheme.reducer;
