/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledArticlesCardContainer = styled.div`
	width: 100%;
	.grid-sizer {
		width: 100%;
		position: relative;
		@media (min-width: 600px) {
			width: calc((100% - 27px) / 2);
		}
		@media (min-width: 768px) {
			width: calc((100% - 27px) / 2);
		}
		@media (min-width: 1024px) {
			width: 430px;
		}
		@media (min-width: 1280px) {
			width: 280px;
		}
		@media (min-width: 1920px) {
			width: 310px;
		}
	}

	.gutter-sizer {
		width: 26px;
		@media (min-width: 768px) {
			width: 27px;
		}
		@media (min-width: 1024px) {
			width: 36px;
		}
		@media (min-width: 1280px) {
			width: 30px;
		}
	}

	.box {
		&:nth-child(3),
		&:nth-child(7n),
		&:nth-child(4n),
		&:nth-child(21n),
		&:nth-child(5n) {
			border-color: #9747ff;
		}
	}
`;

function IsotopeLayout({ children, items, handleCount, articlesRef, allItems, filters }) {
	const isoRef = useRef();
	const isotopeInstance = useRef();
	const contentRef = useRef();
	useEffect(() => {
		const Isotope = require('isotope-layout');
		isotopeInstance.current = new Isotope(isoRef.current, {
			itemSelector: '.box',
			layoutMode: 'masonry',
			transitionDuration: '0.6s',
			masonry: {
				horizontalOrder: true,
				columnWidth: '.grid-sizer',
				gutter: '.gutter-sizer',
			},
		});
		return () => {
			isotopeInstance.current?.destroy();
		};
	}, [items]);

	useEffect(() => {
		const filterString = filters.length > 0 ? filters.join('') : '*';
		isotopeInstance.current.arrange({ filter: filterString });
		if (filterString !== '*' && filterString !== '') {
			handleCount(isotopeInstance.current.filteredItems.length);
		} else {
			handleCount(allItems.length);
		}
	}, [filters]);

	return (
		<div ref={contentRef}>
			<StyledArticlesCardContainer ref={isoRef}>
				<div className="grid-sizer" />
				<div className="gutter-sizer" />
				<div ref={articlesRef}>{children}</div>
			</StyledArticlesCardContainer>
		</div>
	);
}

export default IsotopeLayout;
