/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import React from 'react';
import FooterText from '../../typography/FooterText';
import styled from 'styled-components';

const StyledTextAndTitleBlockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-family: ${({ theme }) => theme.fontFamily.montserratFont};
	margin-bottom: 30px;
	p.title {
		font-weight: ${({ theme }) => theme.fontWeight.semiBold};
		font-size: 13px;
		line-height: 20px;
		margin-bottom: 14px;
		color: ${({ theme }) => theme.colors.footerGray};
		@media (min-width: 1024px) {
			font-size: 17px;
			line-height: 26px;
		}
		@media (min-width: 1280px) {
			font-size: 13px;
			line-height: 20px;
		}
	}
	&.clause {
		width: 100%;
		@media (min-width: 1440px) {
			width: 20%;
		}
	}
	@media (min-width: 768px) {
		padding-right: 20px;
	}
	@media (min-width: 1440px) {
		padding-right: 50px;
	}
`;

const FooterTextAndTitleBlock = ({ data, isLongClause }) => {
	const title = data?.title || '';
	const text = data?.text || '';
	return (
		<StyledTextAndTitleBlockWrapper className={isLongClause ? 'clause text-block' : 'text-block'}>
			<p className="title">{title}</p>
			<FooterText className="gray">{text}</FooterText>
		</StyledTextAndTitleBlockWrapper>
	);
};

export default FooterTextAndTitleBlock;
